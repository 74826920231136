import Hero from "../sections/fms/Hero";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/fms/Content1";
import React from "react";
import Feature2 from "../sections/fms/Feature2";
import Testimonial from "../sections/fms/Testimonial";
import Wnapa from "../sections/fms/Wnapa";
import Call from "../sections/fms/Call";

const fmspage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        <Content1 />
        <Feature2 />
        <Testimonial />
        <Wnapa />
        <Call />
      </PageWrapper>
    </>
  );
};
export default fmspage;
