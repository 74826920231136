import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col xs="12" className="mb-5">
            <div className="text-center">
              <Title>Why Choose Napa Vertical </Title>
            </div>
          </Col>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Title variant="card" fontSize="24px">
                as Your Go-To?
              </Title>
              <Text variant="small" my={4}>
                Our user-friendly facility management software allows you and
                your team to easily adapt to new procedures. In fact, its
                functionality guarantees quick implementation and fast-tracked
                processes from the get-go, so you don’t have to face any
                downtime to benefit from innovation..
              </Text>
              <Text variant="small">
                What’s more, we provide excellent customer support and
                implementation services, giving you peace of mind knowing that
                someone has your back at all times
              </Text>
              <Text variant="small" mt={4}>
                {" "}
                Get started on managing your facility today with cloud-based{" "}
                <a href="/" target="_blank">
                  <Span color="secondary"> facility management software</Span>
                </a>{" "}
                drives definitive productivity.{" "}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
